.app{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(29, 29, 29);
  min-height: 100vh;
  display: flex;
  padding: 20px;
  flex-direction: column;
}

h5,p{
  margin: 0 ;
  color: white;
}

.card{
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: solid white 1px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  text-align: justify;
  /* transform: skewY(5deg); */
}

.card h5{
  font-size: 25px;
  text-align: center;
}

